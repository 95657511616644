import * as React from 'react'
import { useEffect } from 'react'
import Select from '../../base_react_select'
import HiddenInputs from '../../hidden_inputs'
import CreateInstallerButton from './create_installer_button'
import connect from './connect'

type InstallersSelectorProps = {
  value: any
  options: any
  buttonTitle: string
  windowTitle: string
  initialAvailableVendors: { value: number; label: string }[]
  availableVendors: { value: number; label: string }[]
  setAvailableVendors: (vendors: { value: number; label: string }[]) => void
  availableInstallers: any
  setAvailableInstallers: any
  canCreateInstaller: boolean
  installers: any
  setInstallers: any
  createNewInstaller: any
  name: string
  isFetching: boolean
  createInstallerError: string
  createInstallerSuccess: string
  resetCreateInstallerStatusMessages: any
  onChange: (opt: any) => void
  disabled: boolean
}

function InstallersSelector(props: InstallersSelectorProps) {
  const {
    value,
    options,
    buttonTitle,
    windowTitle,
    initialAvailableVendors,
    availableVendors,
    setAvailableVendors,
    availableInstallers,
    setAvailableInstallers,
    canCreateInstaller,
    installers,
    setInstallers,
    createNewInstaller,
    name,
    isFetching,
    createInstallerError,
    createInstallerSuccess,
    resetCreateInstallerStatusMessages,
    onChange,
    disabled,
  } = props
  useEffect(() => {
    if (options !== undefined) {
      setAvailableInstallers(options)
    }
  }, [options])

  useEffect(() => {
    if (value !== undefined) {
      setInstallers(value)
    }
  }, [value])

  useEffect(() => {
    if (availableVendors.length === 0 && initialAvailableVendors !== undefined) {
      setAvailableVendors(initialAvailableVendors)
    }
  }, [])

  const { I18n } = window as any
  const saveUser = (data) => createNewInstaller(data)

  return (
    <div className="d-flex">
      <div className="installers-selector-select flex-grow-1">
        <Select
          isMulti
          value={installers}
          options={availableInstallers}
          onChange={(opt) => {
            setInstallers(opt)
            if (onChange) onChange(opt)
          }}
          isDisabled={disabled}
          placeholder={I18n.t('application.actions.select')}
        />
        <HiddenInputs items={installers} name={name} />
      </div>
      {canCreateInstaller && (
        <div className="ml-3">
          <CreateInstallerButton
            availableVendors={availableVendors}
            saveUser={saveUser}
            isFetching={isFetching}
            createInstallerError={createInstallerError}
            createInstallerSuccess={createInstallerSuccess}
            resetCreateInstallerStatusMessages={resetCreateInstallerStatusMessages}
            buttonTitle={buttonTitle}
            windowTitle={windowTitle}
          />
        </div>
      )}
    </div>
  )
}

export default connect(InstallersSelector)
