import * as React from 'react'
import { Button } from 'reactstrap'
import InstallersSelectorApp from '../../../shared/project_form_controls/installers_selector_app'
import { VCSpinner } from '../../../shared/spinner'

const { I18n } = window as any

type Option = {
  value: number
  label: string
}

interface SelectProjectTicketProps {
  collection: Option[]
  value: number[]
  canCreateInstaller: boolean
  multi: boolean
  assignInstallers: (projectTicketIds: number[], installerIds: number[]) => void
  ready_to_reload_page: boolean
}

export default function SelectProjectTicket(props: SelectProjectTicketProps) {
  const { collection, value, canCreateInstaller, multi, assignInstallers, ready_to_reload_page } = props
  const [isSelectMode, setIsSelectMode] = React.useState<boolean>(false)
  const [selectedProjectTickets, setSelectedProjectTickets] = React.useState<number[]>([])
  const [selectedInstallers, setSelectedInstallers] = React.useState<Option[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const checkboxContainersDisplay = (display: string) => {
    document.querySelectorAll('.checkbox-container').forEach((container) => {
      const htmlCheckbox = container as HTMLInputElement
      htmlCheckbox.style.display = display
    })
  }

  const selectAllTicketsAndCheckboxes = () => {
    let allTicketIds = []

    const checkboxes = document.querySelectorAll('.ticket-checkbox')
    checkboxes.forEach((checkbox) => {
      const htmlCheckbox = checkbox as HTMLInputElement
      allTicketIds.push(parseInt(htmlCheckbox.dataset.ticketId, 10))
      htmlCheckbox.checked = true
    })

    checkboxContainersDisplay('block')

    setSelectedProjectTickets(allTicketIds)
  }

  const handleItemClick = (event: Event) => {
    if (!isSelectMode) return

    event.preventDefault()
    event.stopPropagation()
    const target = (event.target as HTMLElement).closest('.list-group-item')
    const itemId = +target.getAttribute('href').split('/').pop()

    if (itemId) {
      setSelectedProjectTickets((prev) =>
        prev.includes(itemId) ? prev.filter((id) => id !== itemId) : [...prev, itemId]
      )
    }
  }

  React.useEffect(() => {
    const tickets = document.querySelectorAll('.list-group-item')

    tickets.forEach((ticket) => {
      ticket.addEventListener('click', handleItemClick)
    })

    return () => {
      tickets.forEach((ticket) => {
        ticket.removeEventListener('click', handleItemClick)
      })
    }
  }, [isSelectMode])

  React.useEffect(() => {
    const ticketCheckboxes = document.querySelectorAll('.ticket-checkbox')

    ticketCheckboxes.forEach((checkbox) => {
      const inputCheckbox = checkbox as HTMLInputElement
      const itemId = +inputCheckbox.closest('.list-group-item').getAttribute('href').split('/').pop()
      inputCheckbox.checked = selectedProjectTickets.includes(itemId)
    })
  }, [selectedProjectTickets])

  React.useEffect(() => {
    if (ready_to_reload_page) {
      window.location.reload()
    }
  }, [ready_to_reload_page])

  const toggleHighlight = () => {
    setIsSelectMode(!isSelectMode)
    selectAllTicketsAndCheckboxes()

    if (!isSelectMode) {
      checkboxContainersDisplay('block')
    } else {
      checkboxContainersDisplay('none')
      setSelectedProjectTickets([])
    }
  }

  const handleSelectionChange = (selected) => {
    setSelectedInstallers(selected)
  }

  const assignInstallersOnClick = () => {
    setIsLoading(true)

    const tickets = document.querySelectorAll('.list-group-item')
    tickets.forEach((ticket) => {
      ticket.classList.add('project-tickets-disabled')
    })

    assignInstallers(
      selectedProjectTickets,
      selectedInstallers.map((installer) => installer.value)
    )
  }

  return (
    <div className="select-project-ticket-component">
      <div className="project-tickets-select-installers-row">
        <Button color="secondary" onClick={toggleHighlight} disabled={isLoading}>
          {isSelectMode
            ? I18n.t('application.actions.exit_select_mode')
            : I18n.t('application.actions.select_multiple')}
        </Button>
        {selectedProjectTickets.length > 0 && (
          <>
            <div className="project-tickets-selector-expand">
              <InstallersSelectorApp
                options={collection}
                value={value}
                canCreateInstaller={canCreateInstaller}
                multi={multi}
                onChange={handleSelectionChange}
                disabled={isLoading}
              />
            </div>
            <Button color="secondary" onClick={assignInstallersOnClick} disabled={isLoading}>
              {isLoading ? (
                <VCSpinner visible={isLoading} viewType="inline" size="1" />
              ) : (
                I18n.t('application.actions.assign')
              )}
            </Button>
          </>
        )}
      </div>
    </div>
  )
}
