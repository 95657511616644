import React from 'react'

function ChartHeader({ months, monthNames, weekdayNames }) {
  return (
    <>
      <tr className="projects-chart-header-months">
        <th colSpan="3"></th>
        {Object.keys(months).map(yearMonth => {
          const [year, month] = yearMonth.split(',')
          return (
            <th key={month} colSpan={months[yearMonth].length}>
              {monthNames[month]}
            </th>
          )
        })}
      </tr>
      <tr className="projects-chart-header-days">
        <th colSpan="3"></th>
        {Object.keys(months).map(yearMonth => {
          return months[yearMonth].map(day => {
            return <th key={yearMonth + day.date()}>{day.date()}</th>
          })
        })}
      </tr>
      <tr className="projects-chart-header-weekdays">
        <th className="projects-chart-header-title">Firma</th>
        <th className="projects-chart-header-title">Start</th>
        <th className="projects-chart-header-title">Finish</th>
        {Object.keys(months).map(yearMonth => {
          return months[yearMonth].map(day => {
            return <th className="projects-chart-header-weekday" key={yearMonth + day.date()}>{weekdayNames[day.isoWeekday() - 1][0]}</th>
          })
        })}
      </tr>
    </>
  )
}

export default ChartHeader
