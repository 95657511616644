import { useState, useEffect } from 'react'
import classNames from 'classnames'
import store from 'stores/campaign_summary_amounts_store'
import { snakeCase } from 'libs/helpers'
import { ERROR_TYPE_TO_ICON } from 'components/campaigns/summary/config'
import HiddenFields from './hidden_fields'
import AmountInput from './amount_input'
import AmountVariants from './amount_variants'

const I18n = window.I18n

const ErrorIcon = ({ type }) => {
  const ErrorIconView = ERROR_TYPE_TO_ICON[type]

  return (
    <ErrorIconView title={I18n.t(`campaigns.error_messages.${snakeCase(type)}`)} />
  )
}

const listeners = []

const AmountCellItem = (props) => {
  const { minAmount, maxAmount, tagsAmount, elementId, locationId } = props
  const [amount, updateAmount] = useState(props.amount)
  const [errors, updateErrors] = useState([])

  const updateAmountValue = (e) => {
    const value = parseInt(e.target.value) || 0
    updateAmount(value)
  }

  useEffect(() => {
    listeners.push(store.addListener(`ValidationResult-${elementId}-${locationId}`,  (validatorErrors) => {
      updateErrors(validatorErrors)
    }))
    listeners.push(store.addListener(`UpdateAmount-${elementId}-${locationId}`, (newAmount) => {
      updateAmount(newAmount)
    }))
    return () => listeners.forEach(listener => listener.remove())
  }, [])

  useEffect(() => {
    store.setAmount(elementId, locationId, amount, { minAmount, maxAmount, tagsAmount })
  }, [amount])

  const css = classNames(['amount-item', { 'errors': errors.length }])
  return (
    <div className={css}>
      <div className="">
        <AmountVariants min={minAmount} max={maxAmount} tagsAmount={tagsAmount} onChange={updateAmountValue} />
      </div>
      <AmountInput value={amount} min={minAmount} max={maxAmount} onChange={updateAmountValue} />
      <HiddenFields {...props} />
      <div className="error-icons">
        {errors.map((type) => <ErrorIcon key={type} type={type} />)}
      </div>
    </div>
  )
}

export default AmountCellItem
