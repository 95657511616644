import { Map } from 'immutable'
import automation from './automation'
import project from './project'
import projects from './projects'
import element from './element'
import templateFields from './template_fields'
import elementsOrder from './elements_order'
import report from './report'
import rentList from './rent_list'
import common_element_tag from './tag'
import survey from './survey'
import dictionaries from './dictionaries'
import campaigns from './campaigns'
import campaignPostorders from './campaign_postorders'
import overview from './overview'
import uploaderOptions from './uploader_options'
import formBuilder from './form_builder'
import uploader from './uploader'
import manualPages from './manual_pages'
import multiStep from './multi_step'
import sharingLinks from './sharing_links'

import Admin from './admin'

const requestsReducer = (state, action) => {
  const { type } = action
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type)

  if (!matches) return state

  const [, requestName, requestState] = matches

  const reqStatus = state.get(requestName) || Map({})

  switch (requestState) {
    case 'REQUEST':
      return state.set(requestName, reqStatus.set('isFetching', true).set('error', null))
    case 'SUCCESS':
      return state.set(requestName, reqStatus.set('isFetching', false).set('error', null))
    case 'FAILURE':
      if (process.env.NODE_ENV != 'production') { console.error(action.error) }
      return state.set(requestName,
        reqStatus.set('isFetching', false).set('error', action.error || 'REQUEST IS FAILURE'))
    default:
      return state
  }
}

export default function reducer (state, action) {
  return Map({
    requestsStatus: requestsReducer(state.get('requestsStatus'), action),
    automation: automation(state.get('automation'), action),
    project: project(state.get('project'), action, {
      activeElement: state.getIn(['element', 'transient', 'activeElement']),
    }),
    projects: projects(state.get('projects'), action),
    element: element(state.get('element'), action),
    templateFields: templateFields(state.get('templateFields'), action),
    elementsOrder: elementsOrder(state.get('elementsOrder'), action),
    report: report(state.get('report'), action),
    rentList: rentList(state.get('rentList'), action),
    survey: survey(state.get('survey'), action),
    common_element_tag: common_element_tag(state.get('common_element_tag'), action),
    dictionaries: dictionaries(state.get('dictionaries'), action),
    campaigns: campaigns(state.get('campaigns'), action),
    campaign_postorders: campaignPostorders(state.get('campaign_postorders'), action),
    overview: overview(state.get('overview'), action),
    uploaderOptions: uploaderOptions(state.get('uploaderOptions'), action),
    formBuilder: formBuilder(state.get('formBuilder'), action),
    uploader: uploader(state.get('uploader'), action),
    manualPages: manualPages(state.get('manualPages'), action),
    multiStep: multiStep(state.get('multiStep'), action),
    sharingLinks: sharingLinks(state.get('sharingLinks'), action)
  })
}
