import { connect } from 'react-redux'

function mapStateToProps (state) {
  return {
    hiddenFields: state.getIn(['campaigns', 'hiddenFields']).toJS(),
  }
}

function mapDispatchToProps (dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)

