import * as React from 'react'
import { Provider } from 'react-redux'
import store from '../../../../store'
import InstallersSelector from './installers_selector'

type InstallersSelectorAppProps = {
  availableVendors: { value: number; label: string }[]
  canCreateInstaller: boolean
  buttonTitle: string
  windowTitle: string
  name: string
  options: { value: number; label: string }[]
  value: { value: number; label: string }[]
  multi: boolean
}

function InstallersSelectorApp(props: InstallersSelectorAppProps) {
  const { availableVendors, canCreateInstaller, buttonTitle, windowTitle, name, options, value, multi } = props
  return (
    <Provider store={store}>
      <InstallersSelector
        initialAvailableVendors={availableVendors}
        canCreateInstaller={canCreateInstaller}
        buttonTitle={buttonTitle}
        windowTitle={windowTitle}
        name={name}
        options={options}
        value={value}
        multi={multi}
      />
    </Provider>
  )
}

export default InstallersSelectorApp
