import React, { useState, useEffect } from 'react'
import store from 'stores/campaign_summary_amounts_store'

const ElementTotalPrints = (props) => {
  const { elementId, pdfPagesCount, setHiddenFields } = props
  const [totalPrints, updateTotalPrints] = useState(props.totalPrints)

  useEffect(() => {
    const listener = store.addListener(`TotalWasChanged-${elementId}`, () => {
      let totalPrints = store.totalPrintsFor(elementId, pdfPagesCount)
      setHiddenFields({ elementId, totalPrints })
      updateTotalPrints(totalPrints)
    })
    return () => listener.remove()
  }, [elementId, pdfPagesCount])

  return (
    <div className="row">
      <div className="col-12 text-center">{totalPrints}</div>
    </div>
  )
}

export default ElementTotalPrints
