export default ({ id, locationId, campaignElementId, elementId, hiddenFields }) => {

  const element = hiddenFields.find(item => item.elementId === elementId)

  return (
    <>
      { id && <input type="hidden" name="campaign[items_attributes][][id]" value={id} /> }
      <input type="hidden" name="campaign[items_attributes][][location_id]" value={locationId} />
      <input type="hidden" name="campaign[items_attributes][][campaign_element_id]" value={campaignElementId} />
      { hiddenFields.length && element && <input type="hidden" name="campaign[items_attributes][][total_prints]" value={element.totalPrints} />}
    </>
  )
}
