import CompanySelectorApp from './company_selector_app'
import GroupSelectorApp from './group_selector_app'
import SpinnerApp from './spinner_app'
import LocationSelectorApp from './location_selector_app'
import ItemsSelector from './items_selector'
import ServiceCompanySelector from './service_company_selector'
import ServiceCompaniesSelectorApp from './service_companies_selector_app'
import InstallersSelectorApp from './installers_selector_app'
import ElementGroupsSelectorApp from './element_groups_selector_app'
import ElementsSelectorApp from './elements_selector_app'

export default {
  CompanySelectorApp,
  GroupSelectorApp,
  SpinnerApp,
  LocationSelectorApp,
  ItemsSelector,
  ServiceCompanySelector,
  ServiceCompaniesSelectorApp,
  InstallersSelectorApp,
  ElementGroupsSelectorApp,
  ElementsSelectorApp,
}
