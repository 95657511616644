import React from 'react'
import RowItem from './preview/row_item'

const I18n = window.I18n

const Preview = ({elements, removeItem, updateItem, makeCopy,
      clickTagsSelectorControl, imageActions
}) => {

  return (
    <table className="table table-bordered table-hover table-striped table-sm">
      <thead>
        <tr>
          <th className="w-25">{I18n.t('activerecord.models.elements/base_element.one')} ({elements.length})</th>
          <th className="w-10">{I18n.t('activerecord.attributes.elements/base_element.min_amount')}</th>
          <th className="w-10">{I18n.t('activerecord.attributes.elements/base_element.max_amount')}</th>
          <th className="w-15">{I18n.t('activerecord.attributes.elements/base_element.dimensions')}</th>
          <th className="w-15">{I18n.t('campaigns.others.pdf')}</th>
          <th className="w-15">{I18n.t('activerecord.attributes.elements/common_element.tags')}</th>
          <th className="w-10">{I18n.t('application.actions.title')}</th>
        </tr>
      </thead>
      <tbody>
        { elements.map(el => {
          return <RowItem
            id={el.elementID}
            key={el.key()}
            item={el}
            updateItem={updateItem}
            removeItem={removeItem}
            makeCopy={makeCopy}
            clickTagsSelectorControl={clickTagsSelectorControl}
            imageActions={imageActions} />
          })
        }
      </tbody>
    </table>
  )
}

export default Preview
