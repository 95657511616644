import CustomDatatable from 'libs/datatables/custom_datatable'
import RequestPool from 'libs/datatables/request_pool'

export default class CustomDatatableReports extends CustomDatatable {
  constructor(table) {
    super()
    if (table.length === 0) { return }

    // append filter inputs
    table.find('thead').addClass('filters')
    const search_tr = $('<tr/>').addClass('filters')
    table.find('thead tr th').each(function(index, th) {
      const search_input = $('<input />')
        .attr('type', 'text')
        .attr('name', `search[${$(th).data('column')}]`)
        .attr('placeholder', $(th).data('search-placeholder'))
        .addClass(`search_${$(th).data('column')}`);
      th = $('<th/>')
      th.append(search_input)
      const spinner = $('<span>').addClass('loading')
      th.append(spinner)
      search_tr.append(th)
    })

    table.find('thead').append(search_tr)

    // init datatable
    class LoadingIndicator {
      constructor(filterInputs1) {
        this.filterInputs = filterInputs1
        this.sortingCells = table.find('thead.filters tr[role=row] th.sorting')
        this.pager = table.parents('.dataTables_wrapper').find('.datatables-pager')
      }
      show(index) {
        $(this.filterInputs.get(index)).parent().find('.loading').show()
        this._disable(this.sortingCells)
        this._disable(this.pager)
      }
      hide() {
        table.find('tr.filters th .loading').hide()
        this._enable(this.sortingCells)
        this._enable(this.pager)
      }

      _disable(elements) {
        elements.css('pointer-events', 'none')
      }
      _enable(elements) {
        elements.css('pointer-events', 'auto')
      }
    }

    const requestPool = new RequestPool
    const url = table.data('url')
    const cachedTotal = table.data('total')

    const filterInputs = table.find('tr.filters th input')
    const loadingIndicator = new LoadingIndicator(filterInputs)

    const fetchData = function(data, callback, settings) {
      requestPool.abortAll()
      const request = $.ajax({
        url,
        data,
        method: 'POST',
        complete: (request, status) => { requestPool.remove(request) },
        success: (response) => {
          const totalCells = table.find('tfoot tr.totals td')
          if (totalCells.length && response.totals) {
            for (let idx = 0; idx < response.totals.length; idx++) {
              const total = response.totals[idx]
              $(totalCells.get(idx)).html(total)
            }
          }
          // Unlikely amount of rows (unfiltered) will change upon viewing report,
          // so to avoid calculating overal row count in each request
          // it is calculated on page load and stored in data-attribute
          response.recordsTotal = cachedTotal
          callback(response)
          if (response.needToMountReactComponents) {
            ReactRailsUJS.mountComponents('td .report-item-photos')
          }
          loadingIndicator.hide()
        },
        error: (response) => {
          alert(response.responseJSON.message)
          loadingIndicator.hide()
        }
      })

      requestPool.add(request)
    }

    // we should keep order, because datatables throws an error on search if no
    // sorting is defined
    const options = $.extend(this.defaultOptions(), {
      orderCellsTop: true, serverSide: true,
      ajax: fetchData, paging: true,
      info: true,
      dom: '<"table_wrapper"t><"datatables-pager"p>',
      language: {
        paginate: {
          next: '>',
          previous: '<'
        }
      },
      iDisplayLength: 20
    }
    )
    // fill in filter inputs upon state loading
    options['stateLoaded'] = (settings, state) => {
      if (state && state['columns']) {
        const columns = state['columns']
        $(table).find('tr.filters th').each(function(i, el) {
          const filterValue = columns[i]['search']['search']
          const input = $(el).find('input[type="text"]')
          if (filterValue) { input.val(filterValue) }
        })
      }
    }
    const datatable = table.DataTable(options)

    const filterFunction = _.debounce(
      (val, index) => {
        datatable
          .column(index)
          .search(val, true, false)
          .draw()
      },
      500
    )

    datatable.columns().header().each((headerCell, index) =>
      $(filterInputs.get(index)).on('keyup change', function() {
        loadingIndicator.show(index)
        requestPool.abortAll()
        filterFunction(this.value, index)
      })
    )
  }
}
