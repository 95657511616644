import CustomDatatableWithSearch from 'libs/datatables/custom_datatable_with_search'

export default function () {
  $(document).on('ready', function() {

    // elements tab on location page
    const $elementsTabContent = $('#elements')
    if ($elementsTabContent.length > 0) {
      const selector = 'table.custom-datatable tbody td:not(.actions):not(.element-table-photos):not(.gallery)'
      $elementsTabContent.on('click', selector, (e) => {
        window.location.href = $(e.currentTarget).parent('tr').data('url')
      })

      $('.element-group').each(function(index, elements) {
        const table = $(this).find('table')
        const search = $(this).find('input.search')
        let sort_column = $(table).data('sortColumn')
        if (_.isUndefined(sort_column)) { sort_column = 1 }
        const order = [[ sort_column, 'asc']]
        new CustomDatatableWithSearch(table, search, {order})
      })
    }


    // location show page - projects tab
    $('#projects_tab .card').each(function(index, projects) {
      const table = $(this).find('table')
      const search = $(this).find('input.search')
      let sort_column = $(table).data('sortColumn')
      if (_.isUndefined(sort_column)) { sort_column = 1 }
      const order = [[ sort_column, 'asc']]
      const projectsTable = new CustomDatatableWithSearch(table, search, { order })

      // project filter by status
      const $cardCont = $(this)
      return $('select', $cardCont).on('keyup change', function() {
        if (this.value === '') {
          // 3 - it is column index with status
          return projectsTable.api().columns(3).search('').draw()
        } else {
          // 3 - it is column index with status
          return projectsTable.api().column(3).search(this.value, true, false).draw()
        }
      })
    })

    // devices tab
    $('.device-group').each(function(index, elements) {
      const table = $(this).find('table')
      const search = $(this).find('input.search')
      let sort_column = $(table).data('sortColumn')
      if (_.isUndefined(sort_column)) { sort_column = 1 }
      const order = [[ sort_column, 'asc']]
      return new CustomDatatableWithSearch(table, search, {order})
    })

  })

}
